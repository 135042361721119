/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-02-11 09:23:56
 * @LastEditors  : BigBigger
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/assets/css/index.css'

Vue.use(Vant);

Vue.config.productionTip = false

import VUpload from '@/components/common/Upload.vue';
Vue.component('VUpload', VUpload);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
