/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-03-31 11:15:35
 * @LastEditors  : BigBigger
 */
import axios from 'axios'
import store from '@/store/index.js'
import router from '@/router'
import { TIME_OUT } from '@/config/config'
import { Dialog } from 'vant';
import VueRouter from 'vue-router';

const requset = axios.create({
  // baseURL: 'http://47.110.77.30:9900',
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: TIME_OUT,
})

// 添加请求拦截器
requset.interceptors.request.use((config) => {
    // 在发送请求之前做些什么
    if(config.url === 'https://api.polyv.net/live/v3/channel/common/get-chat-token') {
      return config
    }
    let token = store.state.userInfo ? store.state.userInfo.token : null;
    if (token) {
      config.headers.token = token
    }
    return config;
  }, (error) => {
    // 对请求错误做些什么
    return;
  });

// 添加响应拦截器
requset.interceptors.response.use((response) => {
    // 对响应数据做点什么
    if(response.data.returnCode === '999998') {
      Dialog.alert({
        message: '登录信息已失效，请重新登录',
      })
      store.dispatch('clearUserInfo')
      router.replace({path: '/course'})
      setTimeout(() => {
        window.location.reload()
      }, 20000)
    }
    // if(response)
    return response.data;
  }, (error) => {
    // 对响应错误做点什么
    return Promise.reject(error)
  });

export default requset