<template>
  <div id="app">
    <Nav />
    <router-view/>
    <Foot />
    <Side />
  </div>
</template>

<style lang="less" scoped>
#app{
  height: 100%;
  overflow: auto;
}
</style>

<script>
import Nav from '@/components/Layout/Nav.vue'
import Foot from '@/components/Layout/Foot.vue'
import Side from '@/components/Layout/Side.vue'

export default {
  name: 'App',
  components: {
    Nav,
    Foot,
    Side
  },
  mounted() {
    // fix 安卓输入法导致页面无法滚动的问题
    const originHeight = document.body.clientHeight;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (originHeight > window.innerHeight) {
          document.body.style.height = originHeight + 'px'
          // document.activeElement.blur();
        } else {
          document.body.style.height = '100%';
        }
      }, 10);
    })
  },
}
</script>
